@media screen and (min-width: 0px) {
   .h3 {
    /*background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;*/
    margin: 15px;
    padding: 2%;
    position: relative;
    text-align: center;
  }

  .center .h3 {
    opacity: .5;
    transition: all .3s ease;
  }

  .center .slick-center .h3 {
    color: #e67e22;
    opacity: 1;
    -ms-transform: scale(1.08);
    transform: scale(1.08);
  }

}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 1100px) {
  .h3 {
    margin: 25px;
  }
}