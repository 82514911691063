@media screen and (min-width: 0px) {
  .gridContainer {
     &.MuiGrid-container {
      width: calc(100% + 30px);
      margin-top: -30px;
      margin-left: -30px;

      .MuiGrid-item {
        padding-left: 30px;
        padding-top: 30px;
      }
    }
  }

  .gridContainerRegister {
    &.MuiGrid-container{
      padding-top: 0;
      padding-left: 0;
      row-gap: 25px;
      height: 100%;
      margin-top: 0;
      .MuiGrid-item{
        padding-top: 0;
      }
    }
  }

  .gridContainerUserProfil {
    &.MuiGrid-container {


      .MuiGrid-item {
        padding-left: 30px;
        padding-top: 50px;
      }
    }
  }

  .gridContainerLogin {
    &.MuiGrid-container {
      width: calc(100% + 20px);
      margin-top: -10px;
      margin-left: -20px;

      .MuiGrid-item {
        padding-left: 20px;
        padding-top: 10px;
      }
    }
  }


}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 1100px) {
  .gridContainer {
    &.MuiGrid-container {
      width: calc(100% + 30px);
      margin-top: -70px;
      margin-left: -30px;

      .MuiGrid-item {
        padding-left: 30px;
        padding-top: 70px;
      }
    }
  }

}