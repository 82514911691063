@media screen and (min-width: 0px) {
  .image-border-radius {
    width: 165px;
    height: 165px !important;
  }

  .skeleton-slider-image {
    width: 80px;
    height: 80px !important;
  }

  .skeleton-circle-image {
    width: 205px;
    height: 205px !important;
  }

  .skeleton-small-circle {
    width: 68px;
    height: 68px !important;
  }
}

@media screen and (min-width: 600px) {
  .image-border-radius {
    width: 222px;
    height: 250px !important;
  }

  .skeleton-slider-image {
    width: 100px;
    height: 100px !important;
  }

  .skeleton-circle-image {
    width: 225px;
    height: 225px !important;
  }

  .skeleton-small-circle {
    width: 75px;
    height: 75px !important;
  }
}

@media screen and (min-width: 1100px) {
  .image-border-radius {
    width: 350px;
    height: 400px !important;
    border-radius: 50px !important;
    transform: scale(1) !important;
  }

  .skeleton-title {
    width: 50%;
  }

  .skeleton-text {
    height: 14px !important;
    width: 100%;
  }

  .skeleton-text-50 {
    height: 14px !important;
    width: 50%;
  }

  .skeleton-slider-image {
    width: 150px;
    height: 150px !important;
    border-radius: 10px !important;
  }

  .skeleton-circle-image {
    width: 300px;
    height: 300px !important;
    border-radius: 100% !important;
    transform: scale(1) !important;
  }

  .skeleton-small-circle {
    width: 100px;
    height: 100px !important;
    border-radius: 100% !important;
    transform: scale(1) !important;
  }
}