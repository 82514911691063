@media screen and (min-width: 0px) {
  .wrapperButtons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 45px;
  }

  .blockButtons {
    display: flex;
    column-gap: 20px;
  }

  .blurLayout {
    filter: blur(5px);
  }

  .masonryProfilBox {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }
}

@media screen and (min-width: 600px) {
  .wrapperButtons {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1100px) {
  .blockButtons {
    column-gap: 30px;
  }

}