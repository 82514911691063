html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #808bf8;
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

main {
  /*min-height: calc(100vh - 580px);*/
  min-height: calc(100vh - 602px);
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@font-face {
  font-family: 'Century Gothic Bold';
  src: url('../assets/fonts/CenturyGothic-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('../assets/fonts/CenturyGothic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Comfortaa Bold';
  src: url('../assets/fonts/Comfortaa-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Great Vibes';
  src: url('../assets/fonts/GreatVibes-Regular.woff2') format('woff2'),
    url('../assets/fonts/GreatVibes-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@media screen and (max-width: 1199px) {
  /*main {
    padding: 100px 0;
    min-height: calc(100vh - 430px);
  }*/
}

ul.list-values {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  list-style: none;
  padding: 0 5px;
  margin: 0;
}
ul.list-values li {
  line-height: 40px;
}
ul.list-values li::before {
  content: '• ';
  font-size: 20px;
  color: #dfb02d;
  vertical-align: middle;
}
div.list-values {
  padding: 12px 16px;
  background: #ffffff;
  border-radius: 5px;
  margin: 5px;
  float: left;
}
div.list-values span {
  padding: 5px;
  color: red;
  cursor: pointer;
}

.MuiOutlinedInput-root {
  padding: 5px 35px 5px 5px !important;
}

#payment-form #submit {
  width: 100% !important;
  position: relative !important;
}

span.MuiTypography-root.MuiTypography-caption.MuiDayPicker-weekDayLabel.css-46btbf-MuiTypography-root-MuiDayPicker-weekDayLabel {
  color: initial !important;
}
