.fc {
  .fc-scrollgrid-liquid {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #A4ADFF ;
  }

  .fc-scroller-liquid-absolute {
    overflow: hidden;
  }

  thead {
    background: #E3E6FF;
  }
}

.fc-theme-standard td, .fc-theme-standard th {
  border: 1px solid #A4ADFF ;
  font-family: "Century Gothic Bold", sans-serif;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #EEEEEE;
}
